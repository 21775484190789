.mySwiper {
  width: 343px;
  height: 425px;
}

.swiper-slide {
  width: 343px;
  height: 425px;
  border-radius: 16px;
  padding: 18px 10px;
  background: #213040;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
}

.swiper-img {
  background-color: #fff;
  background-position: 50%;
  background-size: cover;
  position: relative;
  width: 80vw;
  max-width: 343px;
  height: 425px;
  max-height: 100%;
  object-fit: contain;
  user-select: none;
  -webkit-user-select: none;
}

.rotation--5 {
  rotate: 5deg !important;
}

.rotation--4 {
  rotate: 4deg !important;
}

.rotation--3 {
  rotate: 3deg !important;
}

.rotation--2 {
  rotate: 2deg !important;
}

.rotation--1 {
  rotate: 1deg !important;
}

.rotation-0 {
  rotate: 0deg !important;
}

.rotation-1 {
  rotate: -1deg !important;
}

.rotation-2 {
  rotate: -2deg !important;
}

.rotation-3 {
  rotate: -3deg !important;
}

.rotation-4 {
  rotate: -4deg !important;
}

.rotation-5 {
  rotate: -5deg !important;
}
