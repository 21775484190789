.gradient::after {
    background-image: radial-gradient(at 27% 37%, #3a8bfd 0, transparent 50%),
        radial-gradient(at 97% 21%, #325eff 0, transparent 50%),
        radial-gradient(at 52% 65%, #fc6684 0, transparent 50%),
        radial-gradient(at 10% 29%, #f86fba 0, transparent 50%),
        radial-gradient(at 97% 96%, #3097ff 0, transparent 50%),
        radial-gradient(at 33% 50%, #53b7ff 0, transparent 50%),
        radial-gradient(at 79% 53%, #eea5ba 0, transparent 50%);
    position: absolute;
    content: "";
    z-index: -50;
    width: 100%;
    height: 100%;
    filter: blur(100px) saturate(150%);
    top: 0px;
    opacity: 0.18;
    transform: translateZ(0);
}