.gradient:after {
    background-image: radial-gradient(at 27% 37%, #3a8bfd 0, transparent 50%),
        radial-gradient(at 97% 21%, #9772fe 0, transparent 50%),
        radial-gradient(at 52% 99%, #fd3a4e 0, transparent 50%),
        radial-gradient(at 10% 29%, #5afc7d 0, transparent 50%),
        radial-gradient(at 97% 96%, #9596e4 0, transparent 50%),
        radial-gradient(at 33% 50%, #8ca8e8 0, transparent 50%),
        radial-gradient(at 79% 53%, #eea5ba 0, transparent 50%);
    position: absolute;
    content: "";
    z-index: -50;
    width: 50%;
    height: 100%;
    filter: blur(100px) saturate(150%);
    z-index: -1;
    top: 0;
    opacity: 0.8;
    transform: translateZ(0);
}

@media (min-width: 768px) {
    .gradient:after {
        opacity: 0.3;
        width: 70%;
    }
}